import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";
import About from "../components/about/About";
import Service from "../components/service/Service";
import Testimonial from "../components/testimonial/Testimonial";
import Blog from "../components/blog/Blog";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import "../assets/scss/main.scss";
import Tarif from "../components/tarif/Tarif";
import { Helmet } from "react-helmet";
import ContactAndInfo from "../components/ContactAndInfo";

const HomeOne = ({data}) => {
  return (
    <>
      <Helmet>
          <title>Yohanna Seychelles | Thérapeutre en aromathérapie </title>
      </Helmet>
      <Header />
      {/* End Header Section */}
      <Slider />
      {/* End Banner Section */}

      {/* About Me */}
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <About />
        </div>
      </section>
      {/* End About Me */}

      {/* Services */}
      <section id="services" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Ce que je propose</h3>
            </div>
          </div>
          {/* End .row */}
          <Service />
        </div>
        <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7 mt-5">
            <p style={{'color':'hsl(50deg 60% 21%)'}} >Je reste dans une démarche d'analyse de ma pratique et de ma posture professionnelle grâce à des supervisions régulières.</p>
            </div>
        </div>
      </section>
      {/* End Services */}

      <div className="section testimonial">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}

      {/* Tarif */}
      <section id="tarifs" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Mes Tarifs</h3>
            </div>
          </div>
          <Tarif />
        </div>
        <div className="row mt-5 justify-content-center section-title text-center">
          <div className="btn-bar">
            <a className="px-btn px-btn-theme" href="https://calendly.com/ysey-contact" target="_blank">
              Prendre rendez-vous
            </a>
          </div>
        </div>
        <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
            <p style={{'color':'hsl(50deg 60% 21%)'}}> Le règlement se fait à la fin de chaque séance en <b>espèces</b> ou par <b>chèques</b>.</p>
            <p style={{'color':'hsl(50deg 60% 21%)', 'maxWidth':'500px', 'margin': 'auto', 'padding':'15px'}}> <b>Annulation: </b>si vous deviez annuler ou reporter une séance, il est impératif de me prévenir au moins <b> 24H à l'avance</b> auquel cas elle vous sera facturée.</p>
            </div>
        </div>
      </section>
      {/* End Tarfis */}


      {/* Blog */}
      <section id="blog" className="section" style={{'background':'hsl(50deg 60% 98%)'}}>
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Mes articles récents</h3>
              <p style={{'color':'hsl(50deg 60% 21%)'}}>
                Retrouvez ici toutes mes explications, mes sujets de réflexion, et mes conseils pratiques.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Blog />
          <div className="text-center mt-5"><a className="px-btn px-btn-theme btn-sm" href="/blog"> Voir plus </a></div>
        </div>
      </section>
      {/*  End Blog */}


      <section style={{'height':450, 'background':'hsl(50deg 60% 98%)'}} className="gray-bg">
          <div className="container">
            <div className="row justify-content-center section-title text-center">
              <div className="col-lg-7">
              <iframe class="mj-w-res-iframe" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/7Xzf/Na9" width="100%"></iframe>
              </div>
            </div>
          </div>
      </section>


      {/* Contact */}
      <section id="contact" className="section after-left-section">
        
            {/* End .col */}

            <ContactAndInfo />

      </section>
      {/* End Contact */}
      

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeOne;

